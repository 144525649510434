/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;

  .helpIcon{
    margin-left:5px;
    color: gray;
    font-size: 18px;
  }
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  box-shadow: 2px 0px 10px 0px rgba(0,0,0,0.16);
  padding: 10px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 13px;
  line-height: 1;
  font-weight:100;
  text-align:left;
  z-index: 1000;
  opacity:1;
  inline-size: 170px;
  overflow-wrap: break-word;
  max-width: 180px;
  width: max-content;
  display: none;
  // for top
  // left: 50%;
  // top: calc(var(--tooltip-margin) * -1);  
  // transform: translateX(-30%) translateY(-55%);
  // for right
  left: calc(2% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.Tooltip-Wrapper:hover .Tooltip-Tip{
  display: block;
}
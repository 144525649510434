table{
    width: 100%;
    margin: 0 !important;
    text-align: center !important;
    color: black;
    background: #eee;

    thead{
        border-bottom: 1.5px solid black;
    }
}
@import "./common/form.scss";
@import "./common/table.scss";
@import "./components/ToolTip.scss";
@import "./screens/HomeScreen.scss";
@import "./screens/RequestEditScreen.scss";

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.color-nav {
  background-color: #f3732c;
}

/* .nav-link {
  color: #fff !important;
  font-size: 0.8rem;
} */

input.checkbox {
  width: 1.2rem;
  height: 1.2rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

.text-field {
  padding: '10px';
  box-shadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset';
  color: 'black';
  font-size: '1.2rem';
}

Link, a{
  cursor: pointer;
  text-decoration:none
}

i{ // for icons
  padding-left: 4px;
}
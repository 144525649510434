#homeScreen{
    margin-top: 20px !important;

    thead, th{
        font-size:12px;
        height: 50px;
        padding: 0 10px;
    }

    tbody, td{
        font-size: 16px;
        font-weight: bold;
        height: 50px;
    }

    td:nth-child(1) {
        color: red;
    }

    td:nth-child(2) {
        color: blue;
    }

    td:nth-child(3) {
        color: darkgreen;
    }

    td:nth-child(4) {
        color: darkorange;
    }

    td:nth-child(5) {
        color: magenta;
    }

    td:nth-child(6) {
        color: darkviolet;
    }
}
#requestEditScreen, #requestViewScreen{
    .formgroup{
        padding: 10px;
        box-shadow:rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        color: black;
        font-size: 1.2rem;
    }

    .label-Tooltip{
        display:flex;
        align-items: center;
    }

    label{
        margin-bottom: 0 !important;
        width: max-content;
    }
    .taskListError{
        margin:0;
        font-size:15px;
        font-weight:lighter;
        color:red;
    }
}